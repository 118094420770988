@mixin linear-text() {
  background: transparent;
  color: transparent;

  &:not(:placeholder-shown) {
    // background: linear-gradient(90deg, #00fe7e 0%, #00c4fe 100%);
    background: linear-gradient(90deg, #00dd6b 0.01%, #00b1e7 100%);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
  }
}
