.outlined-gradient-button {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #fff;
  font-weight: 700;
  &:before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: 0;
    // background: linear-gradient(#161616, #161616) padding-box, linear-gradient(to right, #00fe7e, #00c4fe) border-box;
    background: #f2f2f2;
    border-radius: 0.125em;
    border: 1px solid transparent;
  }
  &:disabled {
    // color: #767676;
    color: #b6b6b6;
    cursor: not-allowed;
  }
  span {
    z-index: 1;
  }
}
