.side-notification-wrapper {
  display: flex;
  padding: 12px;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--white-20, #e9e9e9);
  background: var(--white-100, #fff);

  .side-notification {
    display: flex;
    padding: 12px;
    align-items: center;
    gap: 12px;
    flex: 1 0 0;
    border-radius: 4px;
    border: 2px solid var(--accent-blue-100, #4185ec);
    background: var(--accent-blue-5, #edf8ff);

    /* Drop 3 */
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16);
    & > p {
      color: var(--accent-blue-100, #4185ec);

      /* Note-14/Medium */
      font-family: Centra No2;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 157.143% */
    }
  }

  .side-mint {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;

    .mint-fee {
      display: flex;
      align-items: baseline;
      gap: 9px;

      .p1 {
        color: var(--black-40, #898989);

        /* Note-14/Book */
        font-family: Centra No2;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
      }

      .p2 {
        color: var(--black-100, #1c1c1c);
        font-family: Centra No2;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        text-decoration-line: line-through;
      }
    }

    .mint-badge {
      display: flex;
      align-items: center;
      .mint-badge-wrapper {
        display: flex;
        height: 24px;
        padding: 0px 6px;
        align-items: center;
        gap: 4px;

        border-radius: 24px;
        background: var(--gradient-14, linear-gradient(351deg, #f67265 9.99%, #ffe375 105.93%));

        color: var(--white-100, #fff);
        /* Tini-12/Book */
        text-align: center;
        font-family: Centra No2;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
      }
    }
  }
}

.update-mint {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;

  .mint-fee {
    display: flex;
    align-items: baseline;
    gap: 9px;

    .p1 {
      color: var(--black-40, #898989);

      /* Note-14/Book */
      font-family: Centra No2;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }

    .p2 {
      color: var(--black-100, #1c1c1c);
      font-family: Centra No2;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      text-decoration-line: line-through;
    }
  }

  .mint-badge {
    display: flex;
    align-items: flex-start;
    .mint-badge-wrapper {
      display: flex;
      height: 20px;
      padding: 0px 6px;
      align-items: center;
      gap: 4px;

      border-radius: 24px;
      background: var(--gradient-14, linear-gradient(351deg, #f67265 9.99%, #ffe375 105.93%));

      color: var(--white-100, #fff);
      /* Tini-12/Book */
      text-align: center;
      font-family: Centra No2;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}
